import { axios, toSnakeCase, toCamelCase } from '../../utils';

const BASE_PATH = 'payments';
const METHODS_BASE_PATH = 'payment_methods';
const REQUESTS_BASE_PATH = 'payment_requests';
const API_REQUESTS_BASE_PATH = 'api/v1/payment_requests';

function errorToData(error) {
  if (!error) {
    return undefined;
  }
  return {
    message: error.message,
    relatedObj: error.related_object_id
      ? {
        class: error.related_object_class,
        id: error.related_object_id,
      }
      : undefined,
  };
}

function paymentDataToRequest(data) {
  return {
    id: data.id,
    provider_id: data.providerId,
    child_care_facility_id: data.facilityId,
    amount: data.amount,
    category: data.category,
    date_from: data.from,
    date_to: data.to,
    dependent_ids: data.dependentIds || [data.dependentId || data.dependent?.id],
    dependent_data: toSnakeCase(data.dependentData),
    payment_request_items: toSnakeCase(data.paymentRequestItems),
    payment_request_methods: data.paymentRequestMethods.map(
      ({ initialAmount, paymentMethodId, id }) => ({
        amount: initialAmount,
        payment_method_id: paymentMethodId,
        id,
      }),
    ),
    provider_data: data.newProvider,
    message_to_provider: data.messageToProvider,
    payment_coupons: data.paymentCoupons,
    user_id: data.userId,
    scheduled_at: data.scheduledAt,
    recurring_settings: data.recurringSettings,
    is_pay_me_request: data.isPayMeRequest,
  };
}

function paymentRequestToData({
  amount,
  aasm_state: status,
  child_care_facility_id: facilityId,
  latest_error: error,
  ...request
}) {
  return {
    ...toCamelCase(request),
    amount, // in cents
    status,
    facilityId,
    error: errorToData(error),
  };
}

async function fetchPayees() {
  const response = await axios.get(`/${BASE_PATH}/providers_paid.json`);
  return response.data.providers;
}

async function addProvider(provider) {
  const response = await axios.post(`/${BASE_PATH}/payments_provider.json`, {
    provider: toSnakeCase(provider),
  });
  return response.data;
}

async function addCardPaymentMethod(stripeId, isFsa, userId) {
  const response = await axios.post(`/${METHODS_BASE_PATH}/card.json`, {
    fsa: isFsa,
    stripe_payment_method_id: stripeId,
    user_id: userId,
  });
  return response.data;
}

async function addBankAccountPaymentMethod(publicToken, accountId, userId) {
  const response = await axios.post(`/${METHODS_BASE_PATH}/bank_account.json`, {
    public_token: publicToken,
    account_id: accountId,
    user_id: userId,
  });
  return response.data;
}

async function fetchPaymentMethods() {
  const response = await axios.get(`/${METHODS_BASE_PATH}.json`);
  return response.data;
}

function removePaymentMethod(id) {
  return axios.delete(`/${METHODS_BASE_PATH}/${id}.json`);
}

function canRemovePaymentMethod(id) {
  return axios.get(`/${METHODS_BASE_PATH}/${id}/can_remove.json`);
}

async function addPaymentRequest(paymentData) {
  const payment = paymentDataToRequest(paymentData);

  const response = await axios.post(`/${REQUESTS_BASE_PATH}/checkout.json`, {
    payment_request: payment,
    send_payment: true,
  });

  return paymentRequestToData(response.data);
}

async function updatePaymentRequest(paymentData, id) {
  const payment = paymentDataToRequest(paymentData);
  const response = await axios.put(`/${API_REQUESTS_BASE_PATH}/${id}.json`, {
    payment_request: payment,
    apply_changes_to_collection: paymentData.applyChangesToCollection,
  });

  return paymentRequestToData(response.data);
}

async function updateByProvider(params, id) {
  const response = await axios.put(`/${API_REQUESTS_BASE_PATH}/${id}/update_by_provider.json`, { payment_request: toSnakeCase(params) });
  return toCamelCase(response.data);
}

async function cancelPaymentRequest(id) {
  const response = await axios.put(`/${API_REQUESTS_BASE_PATH}/${id}/cancel.json`);
  return paymentRequestToData(response.data);
}

async function fetchPaymentRequests() {
  const response = await axios.get(`/${API_REQUESTS_BASE_PATH}.json`);
  const paymentRequests = response.data.payment_requests.map(paymentRequestToData);
  return paymentRequests;
}

async function fetchPaymentRequest(id) {
  const response = await axios.get(`/${API_REQUESTS_BASE_PATH}/${id}.json`);
  return paymentRequestToData(response.data);
}

async function fetchPayMeRequest({ id, facilityId }) {
  const { data } = await axios.get(`/${API_REQUESTS_BASE_PATH}/${id}/pay_me_request.json?facility_id=${facilityId}`);
  return toCamelCase(data);
}

async function getPaymentCouponsToApply() {
  const { data } = await axios.get('/payment_coupons.json');

  return toCamelCase(data);
}

async function doPaymentMathAndGetCommissions({
  amount = 0,
  paymentRequestMethods,
  coupons,
  seatId = undefined,
  centerId = undefined,
  providerId,
  paymentRequestId,
}) {
  if (amount === 0 || amount < 0) {
    return toCamelCase({
      total_remaining: amount,
      total_to_be_charged: amount,
      total_to_be_sent_to_provider: amount,
    });
  }

  const params = {
    amount_to_be_sent: amount,
    payment_coupons: coupons,
    payment_methods: toSnakeCase(
      paymentRequestMethods.map(
        ({ paymentMethodId, totalAmount, includesFees = true }) => ({
          id: paymentMethodId,
          includesFees,
          amount: totalAmount,
        }),
      ),
    ),
    seat_id: seatId,
    center_id: centerId,
    provider_id: providerId,
    id: paymentRequestId,
  };

  const { data } = await axios.put(
    `/${API_REQUESTS_BASE_PATH}/commissions.json`,
    params,
  );
  const { totalToBeSentToProvider, paymentMethods, ...rest } = toCamelCase(data);

  return {
    amount: totalToBeSentToProvider,
    paymentRequestMethods: paymentMethods.map(({ id, ...restOfRequest }) => ({
      paymentMethodId: id,
      includesFees: true,
      ...restOfRequest,
    })),
    ...rest,
  };
}

async function recalculateAmount({ paymentRequestMethods, totalAmount }) {
  // eslint-disable-next-line no-param-reassign
  paymentRequestMethods = paymentRequestMethods.map(({ paymentMethodId }) => ({
    paymentMethodId,
    includesFees: false,
    totalAmount,
  }));
  const params = { amount: totalAmount, paymentRequestMethods };
  const data = await doPaymentMathAndGetCommissions(params);
  return data;
}

async function checkNaviaBalance() {
  const { data } = await axios.get(`/${METHODS_BASE_PATH}/navia_balance.json`);

  return toCamelCase(data);
}

async function submitCheckRequest(data) {
  await axios
    .post('/api/v1/providers/request_papercheck.json', {
      papercheck_request: data,
    })
    .then(() => {
      window.localStorage.setItem(
        'kinside:paperCheckOnboardingMessage',
        "We've received your information and are processing the payment. "
          + 'Please allow 7-10 days for the check to arrive. '
          + 'You can mail us at support@kinside.com with any questions related to this process.',
      );
      window.location.replace('/');
    });
}

async function downloadInvoicePdf(paymentRequestId) {
  await axios.get(`/${API_REQUESTS_BASE_PATH}/${paymentRequestId}/invoice_pdf.pdf`, { responseType: 'blob' })
    .then((response) => response.data)
    .then((blob) => {
      const data = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      link.download = 'receipt.pdf';
      link.click();
    });
}

async function declinePayMeRequest(params) {
  const { paymentRequestId, declineReason, declineMessage } = params;
  await axios.post(`/${API_REQUESTS_BASE_PATH}/${paymentRequestId}/decline`, { decline_reason: declineReason, decline_message: declineMessage });
}

async function acceptEnrollmentAutopay(paymentData) {
  const payment = paymentDataToRequest(paymentData);
  await axios.put(`/${API_REQUESTS_BASE_PATH}/${paymentData.id}/accept_enrollment_autopay`, { payment_request: payment });
}

async function fetchUserPayMeRequests() {
  const { data } = await axios.get(`/${API_REQUESTS_BASE_PATH}/requested.json`);
  return toCamelCase(data);
}

export {
  fetchPayees,
  addCardPaymentMethod,
  fetchPaymentMethods,
  addPaymentRequest,
  updatePaymentRequest,
  cancelPaymentRequest,
  fetchPaymentRequests,
  fetchPayMeRequest,
  addBankAccountPaymentMethod,
  addProvider,
  removePaymentMethod,
  getPaymentCouponsToApply,
  doPaymentMathAndGetCommissions,
  checkNaviaBalance,
  submitCheckRequest,
  downloadInvoicePdf,
  recalculateAmount,
  canRemovePaymentMethod,
  declinePayMeRequest,
  fetchUserPayMeRequests,
  acceptEnrollmentAutopay,
  fetchPaymentRequest,
  updateByProvider,
};
